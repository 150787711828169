<template>
    <div class="content-box">
        <el-card class="my-20">
            <div class="d-flex-center">
                <div class="d-inline-flex-center is-link" @click="$nav.back()">
                    <i class="icon icon-back my-8 mr-8" />
                    <span class="color-666">{{ $t('back') }}</span>
                </div>
                <div class="font-16 ml-20 pl-20 mr-auto font-bold color-666 border-left">{{ $t('wallet.title') }}</div>
                <el-button type="primary" icon="el-icon-user" plain @click="$nav.push('/user/account')">{{ $t('navbar.user_center') }}</el-button>
            </div>
        </el-card>
        <el-row :gutter="20" class="mb-50 color-b">
            <el-col :span="5">
                <div
                    v-for="(item, index) in navMenu" :key="index"
                    class="bg-white box-shadow border-r p-20 is-link menu-item d-flex-center"
                    :class="{ active: $route.path == item.route || $route.name == item.name}"
                    @click="onNav(item.route)"
                >
                    <i class="mr-10" :class="item.icon" />
                    <span class="mr-auto" v-text="item.name" />
                    <i class="el-icon-arrow-right" />
                </div>
            </el-col>
            <el-col :span="19">
                <router-view />
            </el-col>
        </el-row>
    </div>
</template>

<script>
export default {
    data() {
        return {
            // active: 0
            navMenu: [
                {
                    icon: 'el-icon-coin',
                    name: this.$t('wallet.spot'),
                    route: '/wallet/spot'
                },
                {
                    icon: 'el-icon-coin',
                    name: this.$t('wallet.futures'),
                    route: '/wallet/futures'
                },
                {
                    icon: 'el-icon-circle-plus-outline',
                    name: this.$t('recharge.title'),
                    route: '/wallet/recharge/USDT'
                },
                {
                    icon: 'el-icon-remove-outline',
                    name: this.$t('withdraw.title'),
                    route: '/wallet/withdraw/USDT'
                },
                {
                    icon: 'el-icon-sort',
                    name: this.$t('exchange.title'),
                    route: '/wallet/exchange'
                },
                {
                    icon: 'el-icon-sort',
                    name: this.$t('transfer.title'),
                    route: '/wallet/transfer'
                }
            ]
        }
    },
    methods: {
        onNav: function (route){
            if (!route) return
            if (this.$route.path == route) return
            this.$nav.replace(route)
        }
    }
}
</script>
<style lang="scss" scoped>
.menu-item{
    border-left: 4px solid transparent;
}
.menu-item.active,
.menu-item:hover{
    border-left: 4px solid $--color-primary;
    color: $--color-primary;
}
</style>